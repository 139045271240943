.CustomScrollView {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.CustomScrollView__box {
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 100px;
  scrollbar-width: none;
  position: relative;
  z-index: 0;
}

.CustomScrollView__box::-webkit-scrollbar {
  display: none;
}

.CustomScrollView__barY:active + .CustomScrollView__box {
  pointer-events: none;
}

.CustomScrollView__barY {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 10px;
}

.CustomScrollView__trackerY {
  width: 10px;
  box-sizing: border-box;
  padding: 4px 4px 4px 0;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
}

.CustomScrollView__trackerY::before {
  content: "";
  opacity: 0.28;
  display: block;
  width: 100%;
  height: 100%;
  background: var(--music_playback_icon);
  border-radius: 12px;
  transition: transform 200ms, opacity 200ms;
}

.CustomScrollView__trackerY:hover::before,
.CustomScrollView__trackerY:active::before {
  opacity: 0.56;
  transform: scaleX(1.34);
}
