/* stylelint-disable */
/*
* Этот файл сгенерирован автоматически. Не надо править его руками.
*/
body[scheme="vkcom_dark"],
[scheme="vkcom_dark"],
.vkuivkcom_dark {
  --accent: #71aaeb;
  --accent_alternate: #ffffff;
  --action_sheet_action_foreground: #ffffff;
  --action_sheet_separator: rgba(255, 255, 255, 0.16);
  --activity_indicator_tint: #656565;
  --attach_picker_tab_active_background: #e1e3e6;
  --attach_picker_tab_active_icon: #222222;
  --attach_picker_tab_active_text: #e1e3e6;
  --attach_picker_tab_inactive_background: #333333;
  --attach_picker_tab_inactive_icon: #e1e3e6;
  --attach_picker_tab_inactive_text: #828282;
  --background_content: #222222;
  --background_highlighted: rgba(255, 255, 255, 0.12);
  --background_hover: rgba(255, 255, 255, 0.08);
  --background_keyboard: #333333;
  --background_light: #292929;
  --background_page: #0a0a0a;
  --background_suggestions: #333333;
  --background_text_highlighted: rgba(81, 129, 184, 0.2);
  --button_bot_shadow: #222222;
  --button_commerce_background: #4bb34b;
  --button_commerce_foreground: #ffffff;
  --button_muted_background: #333333;
  --button_muted_foreground: #e1e3e6;
  --button_outline_border: #e1e3e6;
  --button_outline_foreground: #e1e3e6;
  --button_primary_background: #e1e3e6;
  --button_primary_foreground: #222222;
  --button_secondary_background: #555555;
  --button_secondary_background_highlighted: #656565;
  --button_secondary_destructive_background: #555555;
  --button_secondary_destructive_background_highlighted: rgba(85, 85, 85, 0.6);
  --button_secondary_destructive_foreground: #ff5c5c;
  --button_secondary_destructive_foreground_highlighted: rgba(
    230,
    70,
    70,
    0.16
  );
  --button_secondary_foreground: #e1e3e6;
  --button_secondary_foreground_highlighted: #e1e3e6;
  --button_tertiary_background: rgba(0, 0, 0, 0);
  --button_tertiary_foreground: #e1e3e6;
  --cell_button_foreground: #e1e3e6;
  --content_negative_background: rgba(230, 70, 70, 0.16);
  --content_placeholder_icon: #b2b2b2;
  --content_placeholder_text: #b2b2b2;
  --content_positive_background: rgba(75, 179, 75, 0.16);
  --content_tint_background: #292929;
  --content_tint_foreground: #939393;
  --content_warning_background: rgba(255, 202, 99, 0.16);
  --control_background: #555555;
  --control_foreground: #e1e3e6;
  --control_tint: #222222;
  --control_tint_muted: #939393;
  --counter_primary_background: #ffffff;
  --counter_primary_text: #222222;
  --counter_prominent_background: #ff3347;
  --counter_prominent_text: #ffffff;
  --counter_secondary_background: #828282;
  --counter_secondary_text: #222222;
  --destructive: #ff5c5c;
  --dynamic_blue: #5181b8;
  --dynamic_gray: #aeb7c2;
  --dynamic_green: #4bb34b;
  --dynamic_orange: #ffa000;
  --dynamic_purple: #937ff5;
  --dynamic_raspberry_pink: #f060c0;
  --dynamic_red: #ff3347;
  --dynamic_violet: #a393f5;
  --feed_recommended_friend_promo_background: #6f7985;
  --field_background: #292929;
  --field_border: #424242;
  --field_error_background: rgba(230, 70, 70, 0.16);
  --field_error_border: #e64646;
  --field_text_placeholder: #828282;
  --field_valid_border: #4bb34b;
  --float_button_background: #555555;
  --float_button_background_highlighted: #656565;
  --float_button_border: rgba(0, 0, 0, 0.12);
  --float_button_foreground: #b2b2b2;
  --header_alternate_background: #333333;
  --header_alternate_tab_active_indicator: #e1e3e6;
  --header_alternate_tab_active_text: #e1e3e6;
  --header_alternate_tab_inactive_text: #828282;
  --header_background: #222222;
  --header_background_before_blur: #0a0a0a;
  --header_background_before_blur_alternate: #0a0a0a;
  --header_search_field_background: #424242;
  --header_search_field_tint: #b2b2b2;
  --header_tab_active_background: #656565;
  --header_tab_active_indicator: #5181b8;
  --header_tab_active_text: #e1e3e6;
  --header_tab_inactive_text: #828282;
  --header_text: #e1e3e6;
  --header_text_alternate: #e1e3e6;
  --header_text_secondary: rgba(255, 255, 255, 0.64);
  --header_tint: #e1e3e6;
  --header_tint_alternate: #ffffff;
  --icon_alpha_placeholder: #e1e3e6;
  --icon_medium: #939393;
  --icon_medium_alpha: rgba(255, 255, 255, 0.48);
  --icon_name: #b2b2b2;
  --icon_outline_medium: #b2b2b2;
  --icon_outline_secondary: #939393;
  --icon_secondary: #828282;
  --icon_secondary_alpha: rgba(255, 255, 255, 0.4);
  --icon_tertiary: #656565;
  --icon_tertiary_alpha: rgba(255, 255, 255, 0.24);
  --im_attach_tint: #ffffff;
  --im_bubble_border_alternate: rgba(0, 0, 0, 0);
  --im_bubble_border_alternate_highlighted: rgba(0, 0, 0, 0);
  --im_bubble_button_background: rgba(255, 255, 255, 0.16);
  --im_bubble_button_background_highlighted: rgba(255, 255, 255, 0.24);
  --im_bubble_button_foreground: #e1e3e6;
  --im_bubble_button_outgoing_background: rgba(255, 255, 255, 0.24);
  --im_bubble_gift_background: #857250;
  --im_bubble_gift_background_highlighted: #ab9871;
  --im_bubble_gift_text: #ffffff;
  --im_bubble_gift_text_secondary: #e3d3ac;
  --im_bubble_incoming: #333333;
  --im_bubble_incoming_alternate: #333333;
  --im_bubble_incoming_alternate_highlighted: #656565;
  --im_bubble_incoming_expiring: #262b4d;
  --im_bubble_incoming_expiring_highlighted: #404980;
  --im_bubble_incoming_highlighted: #656565;
  --im_bubble_outgoing: #555555;
  --im_bubble_outgoing_alternate: #555555;
  --im_bubble_outgoing_alternate_highlighted: #828282;
  --im_bubble_outgoing_expiring: #404980;
  --im_bubble_outgoing_expiring_highlighted: #5965b3;
  --im_bubble_outgoing_highlighted: #656565;
  --im_bubble_wallpaper_button_background: #555555;
  --im_bubble_wallpaper_button_background_highlighted: #828282;
  --im_bubble_wallpaper_button_foreground: #e1e3e6;
  --im_bubble_wallpaper_incoming: #333333;
  --im_bubble_wallpaper_incoming_highlighted: #656565;
  --im_bubble_wallpaper_outgoing: #555555;
  --im_bubble_wallpaper_outgoing_highlighted: #656565;
  --im_forward_line_tint: rgba(255, 255, 255, 0.24);
  --im_reply_separator: #ffffff;
  --im_service_message_text: #828282;
  --im_text_name: #71aaeb;
  --im_toolbar_separator: #b2b2b2;
  --im_toolbar_voice_msg_background: #555555;
  --image_border: rgba(255, 255, 255, 0.08);
  --input_background: #424242;
  --input_border: #555555;
  --landing_background: #222222;
  --landing_field_background: #292929;
  --landing_field_border: rgba(0, 0, 0, 0);
  --landing_field_placeholder: #b2b2b2;
  --landing_login_button_background: #e1e3e6;
  --landing_login_button_foreground: #222222;
  --landing_primary_button_background: #e1e3e6;
  --landing_primary_button_foreground: #222222;
  --landing_secondary_button_background: rgba(255, 255, 255, 0.16);
  --landing_secondary_button_foreground: #ffffff;
  --landing_snippet_border: rgba(255, 255, 255, 0.16);
  --landing_tertiary_button_background: rgba(0, 0, 0, 0);
  --landing_tertiary_button_foreground: #ffffff;
  --landing_text_primary: #e1e3e6;
  --landing_text_secondary: #828282;
  --landing_text_title: #ffffff;
  --like_text_tint: #ff5c5c;
  --link_alternate: #939393;
  --loader_background: #555555;
  --loader_tint: #828282;
  --loader_track_fill: #828282;
  --loader_track_value_fill: #ffffff;
  --media_overlay_button_background: #ffffff;
  --media_overlay_button_foreground: #333333;
  --modal_card_background: #333333;
  --modal_card_border: rgba(255, 255, 255, 0.12);
  --modal_card_header_close: #ffffff;
  --music_playback_icon: #ffffff;
  --overlay_status_background: #222222;
  --overlay_status_foreground: #e1e3e6;
  --overlay_status_icon: #edeef0;
  --panel_tab_active_background: #555555;
  --panel_tab_active_text: #e1e3e6;
  --panel_tab_inactive_text: #828282;
  --placeholder_icon_background: rgba(255, 255, 255, 0.08);
  --placeholder_icon_background_opaque: #292929;
  --placeholder_icon_foreground_primary: #939393;
  --placeholder_icon_foreground_secondary: #656565;
  --placeholder_icon_tint: #ffffff;
  --poll_option_background: #ffffff;
  --primary_overlay_badge: rgba(255, 255, 255, 0.4);
  --search_bar_background: #222222;
  --search_bar_field_background: #424242;
  --search_bar_field_tint: #b2b2b2;
  --search_bar_segmented_control_tint: #939393;
  --secondary_overlay_badge: rgba(255, 255, 255, 0.16);
  --segmented_control_bar_background: #222222;
  --segmented_control_tint: #828282;
  --selection_off_icon: #656565;
  --separator_alpha: rgba(255, 255, 255, 0.16);
  --separator_alternate: #292929;
  --separator_common: #424242;
  --skeleton_foreground_from: #292929;
  --skeleton_foreground_to: #333333;
  --skeleton_shimmer_from: #222222;
  --skeleton_shimmer_to: rgba(0, 0, 0, 0);
  --snippet_background: rgba(255, 255, 255, 0.04);
  --snippet_border: rgba(255, 255, 255, 0.16);
  --snippet_icon_tertiary: #656565;
  --splashscreen_icon: #333333;
  --statusbar_alternate_legacy_background: rgba(0, 0, 0, 0);
  --stories_create_button_background_from: #424242;
  --stories_create_button_background_to: #424242;
  --stories_create_button_foreground: #cccccc;
  --stories_create_button_icon_background: #555555;
  --stories_skeleton_loader_background: #555555;
  --switch_ios_off_border: #333333;
  --tabbar_active_icon: #ffffff;
  --tabbar_background: #333333;
  --tabbar_inactive_icon: #828282;
  --tabbar_tablet_active_icon: #5181b8;
  --tabbar_tablet_background: #292929;
  --tabbar_tablet_inactive_icon: #828282;
  --tabbar_tablet_text_primary: #e1e3e6;
  --tabbar_tablet_text_secondary: #828282;
  --text_action_counter: #b2b2b2;
  --text_link: #71aaeb;
  --text_link_highlighted_background: #5181b8;
  --text_muted: #cccccc;
  --text_name: #e1e3e6;
  --text_placeholder: #b2b2b2;
  --text_primary: #e1e3e6;
  --text_secondary: #828282;
  --text_subhead: #939393;
  --text_tertiary: #656565;
  --toolbar_attach_background_from: #828282;
  --toolbar_attach_background_to: #656565;
  --vk_connect_button_primary_background: #e1e3e6;
  --vk_connect_button_primary_foreground: #222222;
  --vk_connect_button_secondary_background: #555555;
  --vk_connect_button_secondary_foreground: #e1e3e6;
  --writebar_icon: #e1e3e6;
}
/* stylelint-enable */
