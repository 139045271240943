.SubnavigationBar__in {
  /* Отрицательный margin нужен, чтобы компенсировать увеличенный из-за тени padding (12px -> 20px) */
  margin: -8px 0;
}

.SubnavigationBar__scrollIn {
  padding: 20px 0;
  display: flex;
}

.SubnavigationBar__scrollIn::before,
.SubnavigationBar__scrollIn::after {
  content: "";
  min-width: 16px;
}

.SubnavigationBar .SubnavigationButton + .SubnavigationButton {
  margin-left: 8px;
}

.SubnavigationBar--fixed .SubnavigationButton {
  flex: 1;
  min-width: 0;
}

/**
 * iOS
 */
.SubnavigationBar--ios .SubnavigationBar__scrollIn::before,
.SubnavigationBar--ios .SubnavigationBar__scrollIn::after {
  min-width: 12px;
}
