.vkui__root--embedded {
  transform: translate3d(0, 0, 0);
  overflow-x: hidden;
}

.AppRoot {
  height: 100%;
}

.vkui__root--embedded .AppRoot {
  overflow: auto;
}

.vkui--sizeX-regular {
  background: var(--background_page);
}

.AppRoot--no-mouse {
  user-select: none;
}
