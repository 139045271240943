.Title {
  display: block;
  margin: 0;
}

.Title--l-1 {
  font-size: var(--vkui--font_title1--font_size--regular);
  line-height: var(--vkui--font_title1--line_height--regular);
  font-weight: var(--vkui--font_title1--font_weight--regular);
}

.Title--l-2 {
  font-size: var(--vkui--font_title2--font_size--regular);
  line-height: var(--vkui--font_title2--line_height--regular);
  font-weight: var(--vkui--font_title2--font_weight--regular);
}

.Title--l-3 {
  font-size: var(--vkui--font_title3--font_size--regular);
  line-height: var(--vkui--font_title3--line_height--regular);
  font-weight: var(--vkui--font_title3--font_weight--regular);
}

/* TODO удалить перед релизом 5.0.0 */
.Title--w-regular {
  font-weight: 400;
}

/* TODO удалить перед релизом 5.0.0 */
.Title--w-medium {
  font-weight: 500;
}

/* TODO удалить перед релизом 5.0.0 */
.Title--w-semibold {
  font-weight: 600;
}

/* TODO удалить перед релизом 5.0.0 */
.Title--w-bold {
  font-weight: 700;
}

/* TODO удалить перед релизом 5.0.0 */
.Title--w-heavy {
  font-weight: 800;
}

.Title--w-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Title--w-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Title--w-3 {
  font-weight: var(--vkui--font_weight_accent3);
}
