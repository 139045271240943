.Subhead {
  margin: 0;
  font-size: var(--vkui--font_subhead--font_size--regular);
  line-height: var(--vkui--font_subhead--line_height--regular);
  font-weight: var(--vkui--font_subhead--font_weight--regular);
}

.Subhead--sizeY-compact {
  font-size: var(--vkui--font_subhead--font_size--compact);
  line-height: var(--vkui--font_subhead--line_height--compact);
}

/* TODO Удалить перед релизом 5.0.0 */
.Subhead--w-regular {
  font-weight: 400;
}

/* TODO Удалить перед релизом 5.0.0 */
.Subhead--w-medium {
  font-weight: 500;
}

/* TODO Удалить перед релизом 5.0.0 */
.Subhead--w-semibold {
  font-weight: 600;
}

/* TODO Удалить перед релизом 5.0.0 */
.Subhead--w-bold {
  font-weight: 700;
}

.Subhead--w-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Subhead--w-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Subhead--w-3 {
  font-weight: var(--vkui--font_weight_accent3);
}
