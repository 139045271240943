.ModalCardBase {
}

.ModalCardBase__container {
  box-sizing: border-box;
  position: relative;
  background: var(--modal_card_background);
  border-radius: 18px;
  padding: 16px;
  margin-bottom: var(--safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: initial;
}

.ModalCardBase__container--softwareKeyboardOpened {
  margin-bottom: 0;
}

.ModalCardBase__header,
.ModalCardBase__subheader {
  text-align: center;
  margin: 0;
  padding: 0 8px;
  word-break: break-word;
}

.ModalCardBase__header {
  color: var(--text_primary);
}

.ModalCardBase__subheader {
  color: var(--text_secondary);
}

.ModalCardBase__header + .ModalCardBase__subheader {
  margin-top: 8px;
}

.ModalCardBase__icon {
  margin: 8px auto 16px;
  color: var(--accent);
}

.ModalCardBase__actions {
  display: flex;
  margin-left: -6px;
  margin-right: -6px;
  margin-top: 16px;
}

.ModalCardBase .UsersStack + .ModalCardBase__actions {
  margin-top: 32px;
}

.ModalCardBase__header + .ModalCardBase__actions,
.ModalCardBase__subheader + .ModalCardBase__actions {
  margin-top: 32px;
}

.ModalCardBase__actions--v {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

/**
 * iOS
 */

.ModalCardBase--ios .ModalCardBase__header:first-child {
  padding-left: 36px;
  padding-right: 36px;
}

/**
 * Android + vkcom
 */

.ModalCardBase--android .ModalCardBase__container,
.ModalCardBase--vkcom .ModalCardBase__container {
  border-radius: 12px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.08);
}

/**
 * Desktop
 */

.ModalCardBase--desktop .ModalCardBase__container {
  border-radius: 8px;
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.16), 0 0 8px rgba(0, 0, 0, 0.12);
}
