.Checkbox {
  display: block;
}

.FormItem .Checkbox {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--formitem_padding));
}

.Checkbox__input {
  display: none;
}

.Checkbox__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Checkbox__icon {
  flex-shrink: 0;
  margin-right: 12px;
}

.Checkbox__icon--on {
  color: var(--accent);
  display: none;
}

.Checkbox__icon--off {
  color: var(--icon_tertiary);
}

.Checkbox__content {
  flex-grow: 1;
  color: var(--text_primary);
  word-break: break-word;
}

.Checkbox__description {
  color: var(--text_secondary);
  display: block;
  margin-bottom: 12px;
  margin-top: 2px;
}

.Checkbox__children {
  margin-top: 12px;
}

.Checkbox__children:last-child {
  margin-bottom: 12px;
}

.Checkbox__input:checked ~ .Checkbox__container .Checkbox__icon--on {
  display: block;
}

.Checkbox__input:checked ~ .Checkbox__container .Checkbox__icon--off {
  display: none;
}

.Checkbox__input[disabled] ~ .Checkbox__container {
  opacity: 0.6;
}

/**
 * iOS
 */

.Checkbox--ios {
  padding: 0 12px;
}

/**
 * Android
 */

.Checkbox--android {
  padding: 0 16px;
}

/**
 * sizeY COMPACT
 */

.Checkbox--sizeY-compact .Checkbox__children {
  margin-top: 8px;
}

.Checkbox--sizeY-compact .Checkbox__children:last-child {
  margin-bottom: 8px;
}

.Checkbox--sizeY-compact .Checkbox__description {
  margin-bottom: 8px;
}

/**
 * VKCOM
 */

.Checkbox--vkcom {
  padding: 0 16px;
}

.Checkbox--vkcom .Checkbox__icon {
  margin-right: 10px;
}

.Checkbox--vkcom .Checkbox__input[disabled] ~ .Checkbox__container {
  opacity: 0.4;
}

.Checkbox--vkcom .Checkbox__children {
  margin-top: 8px;
}

.Checkbox--vkcom .Checkbox__children:last-child {
  margin-bottom: 8px;
}

.Checkbox--vkcom .Checkbox__description {
  margin-top: 1px;
  margin-bottom: 8px;
}
