.Radio {
  display: block;
}

.FormItem .Radio {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--formitem_padding));
}

.Radio__input {
  display: none;
}

.Radio__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Radio__input:disabled ~ .Radio__container {
  opacity: 0.6;
}

.Radio__icon {
  display: block;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  width: 22px;
  height: 22px;
  border: 2px solid var(--icon_tertiary);
  margin-right: 14px;
  transition: border-color 0.15s var(--ios-easing);
}

.Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: var(--accent);
}

.Radio__icon::after {
  position: absolute;
  content: "";
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: 2px;
  top: 2px;
  transform: scale(0.3);
  transition: transform 0.15s var(--ios-easing);
}

.Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: var(--accent);
  transform: scale(1);
}

.Radio__content {
  display: block;
  flex-grow: 1;
  max-width: 100%;
  color: var(--text_primary);
}

.Radio__description {
  display: block;
  color: var(--text_secondary);
  margin-bottom: 12px;
  margin-top: 2px;
}

.Radio__children {
  margin-top: 12px;
}

.Radio__children:last-child {
  margin-bottom: 12px;
}

/**
 * iOS
 */

.Radio--ios {
  padding: 0 12px;
}

/**
 * Android
 */

.Radio--android {
  padding: 0 16px;
}

/**
 * sizeY COMPACT
 */

.Radio--sizeY-compact .Radio__children {
  margin-top: 8px;
}

.Radio--sizeY-compact .Radio__children:last-child {
  margin-bottom: 8px;
}

.Radio--sizeY-compact .Radio__description {
  margin-bottom: 7px;
}

.Radio--sizeY-compact .Radio__icon {
  width: 18px;
  height: 18px;
}

.Radio--sizeY-compact .Radio__icon::after {
  top: 1px;
  left: 1px;
  width: 12px;
  height: 12px;
}

/**
 * VKCOM
 */

.Radio--vkcom {
  padding: 0 16px;
}

.Radio--vkcom .Radio__icon {
  width: 16px;
  height: 16px;
  margin: 2px 14px 2px 2px;
  border: 1px solid var(--icon_secondary);
  border-radius: 50%;
  transition: border-color 0.5s var(--ios-easing);
}

.Radio--vkcom .Radio__icon::after {
  width: 8px;
  height: 8px;
  left: 3px;
  top: 3px;
  transform: scale(0.3);
  transition: transform 0.5s var(--ios-easing);
}

.Radio--vkcom .Radio__input:disabled ~ .Radio__container {
  opacity: 0.4;
}

.Radio--vkcom .Radio__input:checked ~ .Radio__container .Radio__icon {
  border-color: var(--accent);
}

.Radio--vkcom .Radio__input:checked ~ .Radio__container .Radio__icon::after {
  background-color: var(--accent);
  transform: scale(1);
}

.Radio--vkcom .Radio__children {
  margin-top: 8px;
}

.Radio--vkcom .Radio__children:last-child {
  margin-bottom: 8px;
}

.Radio--vkcom .Radio__description {
  margin-top: 1px;
  margin-bottom: 8px;
}

/**
 * CMP:
 * RadioGroup
 */
.RadioGroup .Radio {
  flex: 1;
  margin: 0;
  box-sizing: border-box;
}
