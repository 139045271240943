.Progress {
  border-radius: 1px;
  background: var(--loader_track_fill);
  height: 2px;
}

.Progress__in {
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease;
  background: var(--accent);
}
