.HorizontalScroll {
  position: relative;
  overflow: hidden;
}

.HorizontalScroll__in {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.HorizontalScroll__in::-webkit-scrollbar {
  display: none;
}

.HorizontalScroll__in-wrapper {
  transition: transform 0.2s;
}

.HorizontalScrollArrow--left:hover
  ~ .HorizontalScroll__in
  .HorizontalScroll__in-wrapper {
  transform: translateX(8px);
}

.HorizontalScrollArrow--right:hover
  ~ .HorizontalScroll__in
  .HorizontalScroll__in-wrapper {
  transform: translateX(-8px);
}
