:root {
  /* fonts */
  --font-common: -apple-system, system-ui, Helvetica Neue, Roboto, sans-serif;
  /* @deprecated
   * --font-tt будет удален в 5.0.0, используйте --font-display
   * В коде библиотеки использовать нельзя. Оставлен для обратной совместимости для пользователей библиотеки.
   */
  --font-tt: "TT Commons", -apple-system, system-ui, Helvetica Neue, Roboto,
    sans-serif;
  --font-display: "VK Sans Display", -apple-system, system-ui, Helvetica Neue,
    Roboto, sans-serif;

  /* easings */
  --ios-easing: cubic-bezier(0.36, 0.66, 0.04, 1);
  --android-easing: cubic-bezier(0.4, 0, 0.2, 1);

  /* sizes */
  --tabbar_height: 48px;
  --panelheader_height_ios: 52px;
  --panelheader_height_android: 56px;
  --panelheader_height_vkcom: 48px;
  --modalheader_height_ios: 52px;
  --modalheader_height_android: 56px;
  --search_default_height: 36px;
  --thin-border: 1px;

  /* paddings */
  --formitem_padding: 16px;

  /* colors */
  --white: #fff;
  --blue_200: #5c9ce6;

  /* iOS insets */
  --safe-area-inset-top: 20px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;

  /* animations */
  --duration: 0.7s;
}

@media (min-resolution: 2dppx) {
  :root {
    --thin-border: 0.5px;
  }
}

@media (min-resolution: 3dppx) {
  :root {
    --thin-border: 0.33px;
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}
