.ModalPageHeader {
  position: relative;
  z-index: 2;

  --safe-area-inset-top: 0;
}

.ModalPageHeader--desktop.ModalPageHeader--android {
  padding-left: 6px;
  padding-right: 6px;
}

.ModalPageHeader--desktop.ModalPageHeader--ios {
  padding-left: 4px;
  padding-right: 4px;
}

.ModalPageHeader .Icon--dismiss_24 {
  color: var(--placeholder_icon_foreground_primary);
}

.ModalPageHeader .Icon--cancel_24 {
  color: var(--icon_secondary);
}
