.Spinner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon_outline_secondary);
}

.Spinner__self {
  animation: vkui-rotator var(--duration) linear infinite;
  transform-origin: center;
}

.Spinner__self svg {
  transform: scale(1);
}

/**
 * PanelHeader
 */

.PanelHeader .Spinner {
  color: currentColor;
}

/**
 * Button
 */

.Button .Spinner {
  color: currentColor;
}
