.SegmentedControlOption {
  height: 100%;
  border: 0;
  border-radius: inherit;
  background: transparent;
  color: var(--vkui--color_text_primary);
  transition: color 100ms ease-out;
  user-select: none;
  box-sizing: border-box;
  cursor: pointer;
  appearance: none;
}

.SegmentedControlOption .Icon {
  color: var(--vkui--color_icon_secondary);
}

.SegmentedControlOption:hover {
  opacity: 0.5;
}

.SegmentedControlOption__content {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}
