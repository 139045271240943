.Switch {
  position: relative;
  display: block;
  cursor: pointer;
}

.Switch--disabled {
  cursor: default;
}

.Switch__pseudo {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.Switch__pseudo::before {
  position: absolute;
  content: "";
  top: -3px;
  left: -3px;
  border-radius: 50%;
  box-sizing: border-box;
}

.Switch__self:checked + .Switch__pseudo::before {
  transform: translateX(20px);
}

.Switch__self[disabled] + .Switch__pseudo {
  opacity: 0.4;
}

/**
 * iOS
 */
.Switch--ios .Switch__pseudo {
  width: 51px;
  height: 31px;
  border: 2px solid transparent;
  background: var(--placeholder_icon_background);
  border-radius: 15px;
}

.Switch--ios.Switch--sizeY-compact .Switch__pseudo {
  width: 47px;
  height: 27px;
  border-radius: 13px;
}

.Switch--ios .Switch__self:checked + .Switch__pseudo {
  background: var(--accent);
}

.Switch--ios .Switch__pseudo::before {
  top: 0;
  left: 0;
  width: 27px;
  height: 27px;
  background: var(--white);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06),
    inset 0 0 0 0.5px rgba(0, 0, 0, 0.04);
}

.Switch--ios.Switch--sizeY-compact .Switch__pseudo::before {
  width: 23px;
  height: 23px;
}

/**
 * Android & VKCOM
 */
.Switch--android,
.Switch--vkcom {
  padding: 3px;
}

.Switch--android .Switch__pseudo::after,
.Switch--vkcom .Switch__pseudo::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
}

.Switch--android .Switch__self:checked + .Switch__pseudo::after,
.Switch--vkcom .Switch__self:checked + .Switch__pseudo::after {
  background: var(--accent);
  opacity: 0.48;
}

/**
 * Android
 */
.Switch--android .Switch__pseudo {
  width: 34px;
  height: 14px;
}

.Switch--android .Switch__pseudo::after {
  background: #c6c5c5; /* todo: 1077 replace w/ a variable */
  border-radius: 7px;
}

.Switch--android.Switch--sizeY-compact .Switch__pseudo {
  width: 32px;
  height: 12px;
}

.Switch--android.Switch--sizeY-compact .Switch__pseudo::after {
  border-radius: 6px;
}

.Switch--android .Switch__pseudo::before {
  width: 20px;
  height: 20px;
  background: #f1f1f1; /* todo: 1077 replace w/ a proper variable */
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

.Switch--android.Switch--sizeY-compact .Switch__pseudo::before {
  width: 18px;
  height: 18px;
}

.Switch--android .Switch__self:checked + .Switch__pseudo::before {
  background: var(--accent);
}

.Switch--android.Switch--sizeY-compact
  .Switch__self:checked
  + .Switch__pseudo::before {
  transform: translateX(19px);
}

/**
 * VKCOM
 */
.Switch--vkcom .Switch__pseudo {
  width: 28px;
  height: 10px;
}

.Switch--vkcom .Switch__pseudo::after {
  border-radius: 45px;
  background: var(--icon_tertiary);
  opacity: 0.48;
}

.Switch--vkcom .Switch__pseudo::before {
  left: -2px;
  width: 16px;
  height: 16px;
  background: var(--background_light);
  border: 1px solid var(--icon_tertiary);
  z-index: 1;
}

.Switch--vkcom .Switch__self:checked + .Switch__pseudo::before {
  background: var(--accent);
  border: none;
  transform: translateX(16px);
}

/**
 * [a11y]
 * add animation for browsers that support prefers-reduced-motion
 * so that users with vestibular motion disorders have no problem
 * navigating accessible vkui apps via keyboard
 */
@media (prefers-reduced-motion: no-preference) {
  .Switch__pseudo::before {
    transition: transform 0.1s ease;
  }

  .Switch--ios .Switch__pseudo {
    transition: background-color 0.2s ease, border-color 0.2s ease;
  }

  .Switch--ios .Switch__pseudo::before {
    transition: transform 0.2s cubic-bezier(0.36, -0.24, 0.26, 1.32);
  }

  .Switch--android .Switch__pseudo::after,
  .Switch--vkcom .Switch__pseudo::after {
    transition: background-color 0.1s ease;
  }
}

/**
 * CMP:
 * SimpleCell
 */
.SimpleCell .Switch {
  margin-left: 10px;
}
