.ModalDismissButton {
  position: absolute;
  justify-content: center;
  top: 0;
  right: -56px;
  width: 56px;
  height: 56px;
  padding: 18px;
  box-sizing: border-box;
  color: var(--white);
  transition: opacity 0.15s ease-out;
}

.ModalDismissButton::before {
  display: block;
  content: "";
  top: 14px;
  right: 14px;
  bottom: 14px;
  left: 14px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  position: absolute;
}

/* fixes icon misplacement on Safari in some cases */
.ModalDismissButton .Icon {
  transform: translateX(0);
}

.ModalDismissButton--hover {
  opacity: 0.6;
}

.ModalDismissButton--active {
  opacity: 1;
}
