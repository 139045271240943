.Header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 16px;
  user-select: text;
}

.Header__main {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text_primary);
}

.Header__content {
  display: flex;
  align-items: baseline;
}

.Header__content-in {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Header__content-in--multiline {
  white-space: initial;
  word-break: break-word;
}

.Header--multiline {
  white-space: normal;
}

.Header__subtitle {
  color: var(--text_secondary);
}

.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: -1px;
}

.Header--mode-secondary .Header__main,
.Header--mode-tertiary .Header__main {
  color: var(--text_secondary);
}

.Header--pi .Header__main {
  color: var(--text_primary);
}

.Header__indicator {
  color: var(--text_secondary);
  margin-left: 6px;
  flex-shrink: 0;
}

.Header--mode-primary .Header__indicator {
  color: var(--text_subhead);
}

.Header:not(.Header--pi) .Header__indicator {
  align-self: center;
}

.Header--mode-secondary .Header__indicator {
  margin-left: 8px;
}

.Header__aside {
  white-space: nowrap;
  margin-left: 12px;
}

.Header__aside > .Icon {
  position: relative;
  color: var(--icon_secondary);
}

.Header__aside > .Icon--24 {
  top: 3px;
}

.Header__aside > .Icon--16 {
  top: 1px;
}

/**
 * ANDROID
 */
.Header--android .Header__main {
  margin: 15px 0 9px;
}

.Header--android.Header--mode-tertiary .Header__main {
  margin-top: 11px;
}

/**
 * iOS
 */
.Header--ios {
  padding: 0 12px;
}

.Header--ios .Header__main {
  margin: 13px 0 9px;
}

.Header--ios.Header--mode-secondary .Header__main {
  margin: 14px 0 10px;
}

.Header--ios.Header--mode-tertiary .Header__main {
  margin-top: 9px;
}

/**
 * VKCOM
 */
.Header--vkcom {
  height: 64px;
  align-items: center;
}

.Header--vkcom .Header__main {
  color: var(--text_primary);
}

.Header--vkcom.Header--mode-primary .Header__content:not(:last-child) {
  margin-top: 0;
}

.Header--vkcom.Header--mode-secondary {
  height: 56px;
}

.Header--vkcom.Header--mode-tertiary {
  height: 40px;
}

/**
 * .Group
 */
.Group--plain:not(:first-of-type) .Group__inner > .Header:first-child {
  margin-top: -16px;
}

.Group--card > .Group__inner > .Header:not(.Header--mode-tertiary):first-child {
  margin-top: -4px;
}
